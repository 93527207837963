<template>
    <div class="right">
        <div class="calc-form">
          <country-select
              @select="changeCountry"
          >
          </country-select>
            <div class="item">
                <div class="title">Способ доставки</div>
                <div class="input-wrap">
                    <div class="radio-item-box">
                        <div class="radio-item">
                            <input
                                type="radio"
                                name="delivery-method"
                                id="delivery-method-1"
                                value="cd"
                                class="radio"
                                :checked="deliveryType === 'cd'"
                                @click="changeDeliveryType('cd')"
                            >
                            <label for="delivery-method-1">Курьерская доставка</label>
                        </div>
                        <div class="radio-item">
                            <input
                                type="radio"
                                name="delivery-method"
                                id="delivery-method-2"
                                value="point"
                                class="radio"
                                :checked="deliveryType === 'point'"
                                @click="changeDeliveryType('point')"
                            >
                            <label for="delivery-method-2">До отделения</label>
                        </div>
                    </div>
                </div>
            </div>
            <city-select
                @select="changeCity"
            >
            </city-select>
            <div class="item">
                <div class="title">Вес</div>
                <div class="input-wrap input-weight">
                    <input
                        class="input"
                        type="text"
                        name="weight"
                        v-model="weight"
                        autocomplete="off"
                        @change="changeWeight"
                        @keyup="changeWeight"
                        @keydown="check"
                    >
                    <div class="radio-item-box">
                        <div class="radio-item">
                            <input type="radio" name="weight-calc" id="weight-calc-kg" value="kg" class="radio"
                                   checked="">
                            <label for="weight-calc-kg">кг</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="btn-calc-block-ftr">
                <a :href="redirectUrl" target="_blank" class="btn btn-red">
                  Оформить доставку в личном кабинете
                </a>
            </div>
        </div>
    </div>
</template>

<script>

import CitySelect from './CitySelect'
import CountrySelect from "./CountrySelect.vue";

export default {
    name: 'RightBlock',
    components: {
      CountrySelect,
      CitySelect,
    },
    props: {},
    data() {
        return {
            redirectUrl: 'https://lk.boxberry.ru/ebay/',
            deliveryType: 'point',
            deliveryTypes: {
                'point': 'До отделения',
                'cd': 'Курьерская доставка'
            },
            weight: '',
            maxPointWeight: 15,
            maxCourierWeight: 31,
            utmSource: null,
            utmMedium: null,
            utmCampaign: null,
            utmContent: null,
            utmTerm: null,
            utmCid: null,
            utmUid: null,
        }
    },
    mounted() {

    },
    created() {
        // const originalSetItem = localStorage.setItem
        //
        // localStorage.setItem = function(key, value) {
        //     const event = new Event('localStorageSet');
        //
        //     event.value = value
        //     event.key = key
        //
        //     document.dispatchEvent(event);
        //
        //     originalSetItem.apply(this, arguments);
        // }

        document.addEventListener('DOMContentLoaded', this.createUtm)
    },
    beforeDestroy() {
        document.removeEventListener('DOMContentLoaded', this.createUtm)
    },
    methods: {
        createUtm(){
            let checkYm = setInterval(() => {
                if(window.ym !== 'undefined'){
                    this.getUtm()
                    this.redirectUrl = this.getRedirect('https://lk.boxberry.ru/ebay/')
                    this.setRedirects()
                    clearInterval(checkYm)
                }
            }, 3000);
        },
        changeDeliveryType(type) {
            this.deliveryType = type;
            this.$emit('changeDeliveryType', this.deliveryType);
        },
        changeCity(code) {
            this.$emit('changeCity', code);
        },
        changeWeight() {
            if(this.deliveryType === 'point' && this.weight > this.maxPointWeight){
                this.weight = this.maxPointWeight;
            }
            if(this.deliveryType === 'cd' && this.weight > this.maxCourierWeight){
                this.weight = this.maxCourierWeight;
            }
            this.$emit('changeWeight', this.weight);
        },
        check(e) {
            let float = false,
                str = this.weight;

            if (typeof str === 'string') {
                if ((str.indexOf('.') < 0 && str.indexOf(',') < 0) && str.length > 0) {
                    float = true;
                }
            }

            this.validateDigitKeys(e, float);
        },
        validateDigitKeys(e, float) {
            var allowedKeys = [46, 8, 9, 27, 13];
            if (allowedKeys.indexOf(e.keyCode) !== -1 || (e.keyCode >= 35 && e.keyCode <= 39)) {
                return;
            }
            if (!float && !/\d/.test(e.key)) {
                e.preventDefault();
            } else if (float && !/[0-9,.]/.test(e.key)) {
                e.preventDefault();
            } else {
                return;
            }
        },
        changeCountry(country){
          this.$emit('changeCountry', country);
        },
        getUtm(){
            const queryString = window.location.search
            const urlParams = new URLSearchParams(queryString)

            this.utmSource = urlParams.get('utm_source')
            this.utmMedium = urlParams.get('utm_medium')
            this.utmCampaign = urlParams.get('utm_campaign')
            this.utmContent = urlParams.get('utm_content')
            this.utmTerm = urlParams.get('utm_term')

            if(window.mainMetrikaId && typeof window.ym !== 'undefined'){
                this.utmCid = window.mainMetrikaId
                let userId = null;

                window.ym(window.mainMetrikaId, 'getClientID', function(clientID) {
                    userId = clientID
                })

                this.utmUid = userId
            }

            this.checkUtm()
        },
        checkUtm(){
            if(this.utmSource || this.utmMedium || this.utmCampaign || this.utmContent || this.utmTerm){
                if(this.utmSource !== this.getCookie('utm_source')){
                    this.setCookie('utm_source', this.utmSource, 1);
                }

                if(this.utmMedium !== this.getCookie('utm_medium')){
                    this.setCookie('utm_medium', this.utmMedium, 1);
                }

                if(this.utmCampaign !== this.getCookie('utm_campaign')){
                    this.setCookie('utm_campaign', this.utmCampaign, 1);
                }

                if(this.utmContent !== this.getCookie('utm_content')){
                    this.setCookie('utm_content', this.utmContent, 1);
                }

                if(this.utmTerm !== this.getCookie('utm_term')){
                    this.setCookie('utm_term', this.utmTerm, 1);
                }
            }else{
                this.utmSource = this.getCookie('utm_source')
                this.utmMedium = this.getCookie('utm_medium')
                this.utmCampaign = this.getCookie('utm_campaign')
                this.utmContent = this.getCookie('utm_content')
                this.utmTerm = this.getCookie('utm_term')
            }
        },
        getRedirect(href) {
            const exists = href.includes('?')
            let hasParams = exists

            if(this.utmSource){
                href += (hasParams ? '&' : '?') + 'utm_source=' + this.utmSource
                hasParams = true
            }

            if(this.utmMedium){
                href += (hasParams ? '&' : '?') + 'utm_medium=' + this.utmMedium
                hasParams = true
            }

            if(this.utmCampaign){
                href += (hasParams ? '&' : '?') + 'utm_campaign=' + this.utmCampaign
                hasParams = true
            }

            if(this.utmContent){
                href += (hasParams ? '&' : '?') + 'utm_content=' + this.utmContent
                hasParams = true
            }

            if(this.utmTerm){
                href += (hasParams ? '&' : '?') + 'utm_term=' + this.utmTerm
                hasParams = true
            }

            if (this.utmCid) {
                href += (hasParams ? '&' : '?') + 'utm_counterid=' + this.utmCid
                hasParams = true
            }

            if (this.utmUid) {
                href += (hasParams ? '&' : '?') + 'utm_clientid=' + this.utmUid
                hasParams = true
            }

            return href
        },
        setRedirects(){
            const links = document.getElementsByTagName('a')
            const regEx = new RegExp('^https://lk.boxberry.ru*')

            if(links.length){
                for (let i = 0; i < links.length; i++) {
                    if(links[i].href && regEx.test(links[i].href)){
                        links[i].setAttribute('href', this.getRedirect(links[i].href));
                    }
                }
            }
        },
        setCookie(name, value, days) {
            var expires = '';
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + (days*24*60*60*1000));
                expires = '; expires=' + date.toUTCString();
            }

            document.cookie = name + '=' + (value || '')  + expires + '; path=/';
        },
        getCookie(name) {
            var nameEQ = name + '=';
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) === ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
            }
            return null;
        }
    },
}
</script>

<style scoped>

</style>

<template>
    <div class="left">
        <div class="delivery-info">
            <div class="cost">
                <h4>СТОИМОСТЬ</h4>
                <p style="position: relative;">
                    <strong>
                        <span class="parcel-price" v-if="costRub">{{ costRub }}</span>
                        <span class="parcel-price" v-else>-</span>
                        Руб.
                    </strong>
                </p>
                <p v-if="showDollars" style="position: relative;">
                    <strong>
                        <span class="parcel-price-usd" v-if="costUsd">{{ costUsd }}</span>
                        <span class="parcel-price-usd" v-else>-</span>
                        $</strong>
                </p>
            </div>
            <div class="type-delivery">
                <h4>ТИП ДОСТАВКИ</h4>
                <ul>
                    <li>Склад{{ countryName }}</li>
                    <li class="delivery-type-text">{{ deliveryTypes[deliveryType] }}</li>
                </ul>
            </div>
            <div class="time">
                <h4>Время</h4>
                <p>{{ deliveryTime }} рабочих дней<span v-if="disclaimerText">*</span></p>
                <div v-if="showSuggest" class="time-suggest">с момента оплаты доставки</div>
                <div v-if="disclaimerText" class="time-suggest">{{ disclaimerText }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'LeftBlock',
        props: ['deliveryType', 'costRub', 'costUsd', 'countryName', 'deliveryTime', 'showSuggest', 'disclaimerText'],
        data() {
            return {
                deliveryTypes: {
                    'point': 'Пункт выдачи',
                    'cd': 'Курьерская доставка'
                },
                showDollars: false
            }
        },
    }
</script>

<style scoped>
</style>

<template>
    <div id="boxberryImportCalculator">
        <div class="container">
            <div class="row calculator">
                <div class="col-lg-12">
                    <h2 v-if="title">{{ title }}</h2>
                    <div class="calc-block calc-1-block active">
                        <left-block
                            :deliveryType="deliveryType"
                            :deliveryTime="deliveryTime"
                            :showSuggest="showSuggest"
                            :disclaimerText="disclaimerText"
                            :costUsd="costUsd"
                            :costRub="costRub"
                            :countryName="countryName"
                        >
                        </left-block>
                        <right-block
                            @changeDeliveryType="changeDeliveryType"
                            @changeCity="changeCity"
                            @changeWeight="changeWeight"
                            @changeCountry="changeCountry"
                        >
                        </right-block>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import LeftBlock from './components/LeftBlock.vue'
import RightBlock from './components/RightBlock.vue'
import {default as axios} from 'axios';
import config from './config';

var css = '@import url("https://bxbox.boxberry.ru/themes/bxbox/assets/css/fonts.css");',
    head = document.head || document.getElementsByTagName('head')[0],
    style = document.createElement('style');

head.appendChild(style);
style.type = 'text/css';
if (style.styleSheet) {
    // This is required for IE8 and below.
    style.styleSheet.cssText = css;
} else {
    style.appendChild(document.createTextNode(css));
}

export default {
    name: 'App',
    components: {
        LeftBlock,
        RightBlock
    },
    data() {
        return {
            title: 'Рассчитайте стоимость доставки',
            deliveryType: 'point',
            deliveryTime: '7-16',
            showSuggest: true,
            costUsd: 0,
            costRub: 0,
            weight: 0,
            city: null,
            countryName: '',
            countryCode: '',
            disclaimerText: ''
        }
    },
    methods: {
        changeDeliveryType(type) {
            this.deliveryType = type;
            this.calculate();
        },
        changeCity(city) {
            this.city = city;
            this.calculate();
        },
        changeWeight(weight) {
            this.weight = weight;
            this.calculate();
        },
        changeCountry(country) {
            this.countryCode = country.code;
            this.disclaimerText = ''
            this.showSuggest = true

            if (country.code == 840) {
                this.countryName = ' в США'
                this.deliveryTime = '7-18'
                this.disclaimerText = '*в период изменения логистических маршрутов и повышенного спроса сроки могут быть увеличены'
                this.showSuggest = false
            } else if (country.code == 276) {
                this.countryName = ' в Германии'
                this.deliveryTime = '14-25'
            } else if (country.code == 792) {
                this.countryName = ' в Турции'
                this.deliveryTime = 'от 5'
                this.disclaimerText = '*Точные сроки зависят от формирования и наполнения партии'
                this.showSuggest = false
            }else if (country.code == 356) {
                this.countryName = ' в Индии'
                this.deliveryTime = 'от 7'
                this.showSuggest = false
            }else if (country.code == 724) {
                this.countryName = ' в Испании'
                this.deliveryTime = '9-17'
            }
            else if (country.code == 156) {
                this.countryName = ' в Китае'
                this.deliveryTime = '7-10'
            }
            this.calculate();
        },
        calculate() {
            if (this.countryCode && this.city && this.weight) {
                this.loadPrice();
            }
        },
        loadPrice() {
            axios
                .get(config.calculateUrl, {
                    params: {
                        delivery: this.deliveryType === 'point' ? 0 : 1,
                        city: this.city,
                        weight: this.weight,
                        country: this.countryCode
                    },
                })
                .then((response) => {
                    if (response.data) {
                        if (response.data.costUsd) {
                            this.costUsd = response.data.costUsd;
                        }
                        if (response.data.cost) {
                            this.costRub = response.data.cost;
                        }
                    } else {
                        console.log(response);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    }
}
</script>

<style>
#boxberryImportCalculator * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

#boxberryImportCalculator {
    display: block;
}

#boxberryImportCalculator ::-moz-selection {
    background-color: #27477c;
    color: #fff
}

::selection {
    background-color: #27477c;
    color: #fff
}

#boxberryImportCalculator {
    font-size: 16px;
    min-width: 320px;
    position: relative;
    line-height: 1.65;
    font-family: Montserrat-Regular, sans-serif;
    overflow-x: hidden;
    color: #3a5a8f;
}

#boxberryImportCalculator .container {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    #boxberryImportCalculator .container {
        max-width: 540px;
        padding: 0;
    }
}

@media (min-width: 768px) {
    #boxberryImportCalculator .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    #boxberryImportCalculator .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    #boxberryImportCalculator .container {
        max-width: 1072px;
    }
}

#boxberryImportCalculator input {
    outline: 0;
}

#boxberryImportCalculator .row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
}

#boxberryImportCalculator .col-lg-12 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

#boxberryImportCalculator .calculator h2 {
    text-align: center;
    margin-bottom: 60px;
    font-size: 34px;
    line-height: 1.2;
    font-family: Gilroy-ExtraBold;
}

#boxberryImportCalculator .calculator .calc-block.active {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

#boxberryImportCalculator li, ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

#boxberryImportCalculator label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

#boxberryImportCalculator .calculator .radio-item-box {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

#boxberryImportCalculator .calculator .radio-item-box .radio-item .radio {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin: 10px 0 0 7px;
}

#boxberryImportCalculator .calculator .radio-item-box .radio-item .radio + label {
    position: relative;
    cursor: pointer;
    z-index: 1;
    color: #173059;
    text-align: center;
    line-height: 38px;
    font-size: 13px;
    margin-bottom: 0;
    padding-left: 15px;
    padding-right: 15px;
}

#boxberryImportCalculator .calculator .radio-item-box .radio-item .radio + label:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: -1;
}

#boxberryImportCalculator .calculator .radio-item-box .radio-item .radio + label:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #3a5a8f;
    opacity: 0;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    z-index: -1;
}

#boxberryImportCalculator .calculator .radio-item-box .radio-item:first-child .radio + label:before {
    -webkit-border-radius: 3px 0 0 3px;
    border-radius: 3px 0 0 3px;
    border: 2px solid #3a5a8f;
}

#boxberryImportCalculator .calculator .radio-item-box .radio-item:first-child .radio + label:after {
    -webkit-border-radius: 3px 0 0 3px;
    border-radius: 3px 0 0 3px;
}

#boxberryImportCalculator .calculator .radio-item-box .radio-item .radio:checked + label {
    color: #fff;
}

#boxberryImportCalculator .calculator .radio-item-box .radio-item .radio:checked + label:after {
    opacity: 1;
}

#boxberryImportCalculator .calculator .radio-item-box .radio-item:last-child .radio + label:before {
    -webkit-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0;
    border: 2px solid #3a5a8f;
}

#boxberryImportCalculator .calculator .radio-item-box .radio-item:last-child .radio + label:after {
    -webkit-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0;
}

#boxberryImportCalculator .city-select-calc .select {
    position: relative;
}

#boxberryImportCalculator .city-select-calc .select {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    border: 2px solid #3a5a8f;
    text-indent: 15px;
    padding: 5px 0 6px;
    font-size: 14px;
    font-family: Montserrat-Medium;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    cursor: pointer;
}

#boxberryImportCalculator .city-select-calc .select:before {
    position: absolute;
    top: 13px;
    right: 15px;
    width: 11px;
    height: 10px;
    display: block;
    content: '';
    background-image: url(./assets/img/select-dropdown-blue-arrow.png);
    background-repeat: no-repeat;
}

@media (min-width: 992px) {
    #boxberryImportCalculator .col-lg-12 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

#boxberryImportCalculator .calculator .calc-block .right {
    padding-left: 90px;
    -webkit-flex-basis: -webkit-calc(100% - 273px);
    -ms-flex-preferred-size: calc(100% - 273px);
    flex-basis: calc(100% - 273px);
}

#boxberryImportCalculator .calculator .calc-block .right .calc-form .item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 25px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

#boxberryImportCalculator .item {
    position: relative;
}

#boxberryImportCalculator .calculator .calc-block .right .calc-form .item .title {
    -webkit-flex-basis: 180px;
    -ms-flex-preferred-size: 180px;
    flex-basis: 180px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    font-size: 14px;
    font-family: Montserrat-SemiBold;
}

#boxberryImportCalculator .calculator .calc-block .right .calc-form .item .input-wrap {
    -webkit-flex-basis: -webkit-calc(100% - 180px);
    -ms-flex-preferred-size: calc(100% - 180px);
    flex-basis: calc(100% - 180px);
}

#boxberryImportCalculator .input-wrap {
    position: relative;
}

#boxberryImportCalculator .select-dropdown {
    position: absolute;
    left: 0;
    width: auto !important;
    right: 0;
    bottom: auto !important;
    top: 60px !important;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    color: #575757;
    border-top: 0;
    z-index: 100;
    padding: 5px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    background-color: #f9f9f9;
    user-select: none;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
}

#boxberryImportCalculator .city-select-calc .select-dropdown {
    top: 40px !important;
}

#boxberryImportCalculator .select-dropdown-search input {
    border-radius: 3px;
    background-color: #f9f9f9;
    text-indent: 15px;
    padding: 8px 0 7px;
    font-size: 14px;
    width: 100%;
    border: 2px solid #a2a4a9 !important;
    margin-bottom: 20px;
    padding-right: 30px;
}

#boxberryImportCalculator .select-dropdown ul li {
    padding: 5px 10px;
    cursor: pointer;
    user-select: none;
}

#boxberryImportCalculator .calculator .calc-block .left {
    -webkit-flex-basis: 273px;
    -ms-flex-preferred-size: 273px;
    flex-basis: 273px;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

#boxberryImportCalculator .calculator .calc-block .delivery-info .type-delivery li {
    font-family: Montserrat-SemiBold;
    position: relative;
    padding-left: 15px;
    margin-bottom: 22px;
    line-height: 1.2;
}

#boxberryImportCalculator .calculator .calc-block .delivery-info .type-delivery li:before {
    content: '';
    position: absolute;
    top: 4px;
    left: 0;
    width: 8px;
    height: 8px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background-color: #fa4b47;
}

#boxberryImportCalculator .calculator .calc-block .delivery-info .type-delivery li:after {
    content: '';
    position: absolute;
    left: 3px;
    top: 4px;
    width: 2px;
    height: -webkit-calc(100% + 25px);
    height: calc(100% + 25px);
    border-left: 2px dashed #fa4b47;
}

#boxberryImportCalculator .calculator .calc-block .delivery-info .type-delivery li:last-child {
    margin-bottom: 0;
}

#boxberryImportCalculator .calculator .calc-block .delivery-info .type-delivery li:last-child:after {
    display: none
}

#boxberryImportCalculator .calculator .calc-block .delivery-info .time p {
    font-family: Montserrat-SemiBold;
}

#boxberryImportCalculator .calculator .calc-block .delivery-info {
    border: 2px solid #d5e2f7;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    padding: 32px;
}

#boxberryImportCalculator .calculator .calc-block .delivery-info h4 {
    font-size: 12px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0.5rem;
}

#boxberryImportCalculator .calculator .calc-block .delivery-info .cost p {
    font-size: 22px;
    font-family: Gilroy-ExtraBold;
    color: #fa4b47;
    line-height: 1.2;
}

#boxberryImportCalculator .calculator .calc-block .delivery-info .type-delivery {
    margin: 20px 0 30px;
    font-size: 13px;
}

#boxberryImportCalculator .select-dropdown ul {
    user-select: none;
}

#boxberryImportCalculator .select-dropdown ul li {
    padding: 5px 10px;
    cursor: pointer;
    user-select: none;
}

#boxberryImportCalculator .select-dropdown ul li:hover {
    background-color: #F0F5FD;
}

#boxberryImportCalculator .select-dropdown ul li.unselectable {
    cursor: default;
}

#boxberryImportCalculator .select-dropdown ul li.unselectable:hover {
    background: none;
}

#boxberryImportCalculator .calculator .input-weight {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: auto;
}

#boxberryImportCalculator .calculator .calc-block .right input.input {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    border: 2px solid #3a5a8f;
    text-indent: 15px;
    padding: 8px 0 9px;
    font-size: 14px;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

#boxberryImportCalculator .calculator .calc-block .right input.input:focus {
    border: 2px solid #fa4b47;
}

#boxberryImportCalculator .calculator .radio-item-box .radio-item #weight-calc-kg.radio + label:before {
    border: 2px solid #3a5a8f !important;
    z-index: 1;
    background: none;
    border-radius: 3px;
}

#boxberryImportCalculator .calculator .input-weight .input {
    margin-right: 10px;
}

#boxberryImportCalculator .calculator .radio-item-box .radio-item #weight-calc-kg.radio + label {
    cursor: default;
    user-select: none;
}

#boxberryImportCalculator .calculator .radio-item-box .radio-item #weight-calc-kg.radio + label:before {
    border: 2px solid #3a5a8f !important;
    z-index: 1;
    background: none;
    border-radius: 3px;
}

#boxberryImportCalculator .calculator .radio-item-box .radio-item #weight-calc-kg.radio:checked + label {
    color: #173059;
}

#boxberryImportCalculator .calculator .radio-item-box .radio-item #weight-calc-kg.radio + label:after {
    background: #fff;

}

#boxberryImportCalculator .calculator .btn-calc-block-ftr {
    margin-top: 35px;
}

#boxberryImportCalculator .calculator .btn-calc-block-ftr .btn-red {
    padding: 9px 21px 10px;
}

#boxberryImportCalculator .right .btn {
    font-size: 14px;
    line-height: 19px;
}

#boxberryImportCalculator .btn-red {
    background-color: #fa4b47;
    color: #fff;
}

#boxberryImportCalculator .btn {
    -webkit-border-radius: 3px;
    border-radius: 3px;
    font-size: 13px;
    font-family: Montserrat-SemiBold;
    padding: 13px 21px 14px;
    display: inline-block;
}

#boxberryImportCalculator .btn-red:hover {
    background-color: #27477c;
    color: #fff;
}

#boxberryImportCalculator a, a:hover {
    text-decoration: none;
}

@media (max-width: 991.98px) {
    #boxberryImportCalculator .calculator .calc-block {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}

@media (max-width: 991.98px) {
    #boxberryImportCalculator .calculator .calc-block .left {
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        -webkit-box-ordinal-group: 3;
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2;
        margin-top: 30px;
    }
}

@media (max-width: 991.98px) {
    #boxberryImportCalculator .calculator .calc-block .right {
        padding-left: 0;
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
    }
}

@media (max-width: 767.98px) {
    #boxberryImportCalculator .calculator .calc-block .right .calc-form .item {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    #boxberryImportCalculator .calculator .calc-block .right .calc-form .item .title {
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        margin-bottom: 10px;
    }

    #boxberryImportCalculator .calculator .calc-block .right .calc-form .item .input-wrap {
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    }
}

@media (max-width: 575.98px) {
    #boxberryImportCalculator .calculator .calc-block .delivery-info {
        border: none;
        padding: 0;
        padding-top: 25px;
        -webkit-border-radius: 0;
        border-radius: 0;
        border-top: 1px solid #f9f9f9;
    }

    #boxberryImportCalculator .calculator h2 {
        font-size: 27px;
        text-align: left;
        margin-bottom: 25px;
    }

    #boxberryImportCalculator .calculator .calc-block .delivery-info .cost p, .calculator .calc-block .delivery-info .time p {
        font-size: 17px;
        color: #fa4b47;
    }

    #boxberryImportCalculator .calculator .calc-block .delivery-info .cost,
    #boxberryImportCalculator .calculator .calc-block .delivery-info .time {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        font-family: Gilroy-ExtraBold;
    }

    #boxberryImportCalculator .calculator .calc-block .delivery-info .type-delivery {
        display: none;
    }
}

#boxberryImportCalculator .calculator .time-suggest {
    font-weight: 400;
    font-size: 12px;
}

#boxberryImportCalculator .calculator .time p {
    margin-bottom: 0px;
}
#boxberryImportCalculator .city-name {
    font-size: 14px;
    font-family: Gilroy;
    font-weight: 500;
    color: #575757;
}
#boxberryImportCalculator .region-name{
    color: rgba(87, 87, 87, 0.50);
    font-size: 12px;
    font-family: Gilroy;
    font-weight: 500;
}

</style>

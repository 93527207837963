<template>
    <div class="item city-select city-select-calc">
        <div class="title">Страна склада</div>
        <div class="input" style="position: relative;">
            <div
                class="select not-selected"
                style="width: 300px;"
                @click="open"
            >
                {{ selectText }}
            </div>
            <div class="select-dropdown" v-show="opened">
                <ul>
                    <li
                        v-for="(option, index) in list"
                        :key="index"
                        @click="select(index)"
                    >
                        {{ option.title }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
 </template>

<script>

export default {
    name: 'CountrySelect',
    props: {},
    data() {
        return {
            selectText: 'Выберите из списка',
            country: null,
            countries: [
                {title: 'США', code: '840'},
                {title: 'Германия', code: '276'},
                {title: 'Испания', code: '724'},
                {title: 'Турция', code: '792'},
                {title: 'Индия', code: '356'},
                {title: 'Китай', code: '156'},
            ],
            opened: false,
            searchString: '',
            searchOptions: null,
            found: 0
        }
    },
    created() {
    },
    beforeDestroy() {
        //document.removeEventListener('click', this.clickOutside);
    },
    computed: {
        list:{
            get(){
                return this.searchOptions || this.countries
            },
            set(val){
                this.searchOptions = val;
            }
        }
    },
    methods: {
        open(){
            this.opened = !this.opened;
        },
        select(index) {
            if (this.list[index]) {
                this.$emit('select', this.list[index]);
                this.selectText = this.list[index].title;
            }
            this.opened = false;
            this.clear();
        },
        doSearch(){
            this.list = [];
            let str = this.searchString.toLowerCase();

            for(let i = 0; i < this.cities.length; i++){
                if(this.cities[i].title.toLowerCase().startsWith(str)){
                    this.list.push(this.cities[i]);
                }
            }
        },
        clear() {
            this.searchString = '';
            this.list = null;
        },
        clickOutside(e) {
            this.opened = this.$el.contains(e.target) && this.opened;
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <div class="item city-select city-select-calc">
        <div class="title">Город получателя</div>
        <div class="input" style="position: relative;">
            <div
                class="select not-selected"
                style="width: 300px;"
                @click="open"
            >
                {{ selectText }}
            </div>
            <div class="select-dropdown" v-show="opened">
                <div class="select-dropdown-search" style="max-width: 240px;">
                    <input
                        type="text"
                        name="select-city-search"
                        placeholder="Поиск"
                        autocomplete="off"
                        v-model="searchString"
                        @input="doSearch"
                    >
                </div>
                <ul>
                    <li v-if="!list.length" class="unselectable">Ничего не найдено</li>
                    <li
                        v-for="(option, index) in list"
                        :key="index"
                        @click="select(index)"
                    >
                        <span class="city-name">{{ option.title }}</span>
                        <br/>
                        <span class="region-name">{{ option.title }}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
 </template>

<script>

import {default as axios} from 'axios';
import config from '../config';

export default {
    name: 'CitySelect',
    props: {},
    data() {
        return {
            selectText: 'Выберите из списка',
            city: null,
            cities: [],
            opened: false,
            searchString: '',
            searchOptions: null,
            found: 0
        }
    },
    created() {
        //document.addEventListener('click', this.clickOutside);
        this.loadCities();

    },
    beforeDestroy() {
        //document.removeEventListener('click', this.clickOutside);
    },
    computed: {
        list:{
            get(){
                return this.searchOptions || this.cities
            },
            set(val){
                this.searchOptions = val;
            }
        }
    },
    methods: {
        open(){
            this.opened = !this.opened;
        },
        loadCities() {
            axios
                .get(config.getCitiesUrl)
                .then((response) => {
                    if (response.data) {
                        for (let i = 0; i < response.data.length; i++) {
                            if (response.data[i].code && response.data[i].title) {
                                this.cities.push(response.data[i]);
                            }
                        }
                    } else {
                        console.log(response);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        select(index) {
            if (this.list[index]) {
                this.$emit('select', this.list[index].code);
                this.selectText = this.list[index].title;
            }
            this.opened = false;
            this.clear();
        },
        doSearch(){
            this.list = [];
            let str = this.searchString.toLowerCase();

            for(let i = 0; i < this.cities.length; i++){
                if(this.cities[i].title.toLowerCase().startsWith(str)){
                    this.list.push(this.cities[i]);
                }
            }
        },
        clear() {
            this.searchString = '';
            this.list = null;
        },
        clickOutside(e) {
            this.opened = this.$el.contains(e.target) && this.opened;
        }
    }
}
</script>

<style scoped>

</style>
